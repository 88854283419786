import React from "react";
import "./Reg_Info.css";
import { Link } from "react-router-dom";

function Reg_Info() {
  return (
    <div className="regis_info">
      <div className="heading1 abstract_mb40">Workshop Registration</div>

      <div className="guidelines">
        <div className="g_bold g_title">
          Guidelines for pre-conference workshop registration
        </div>
        <ol className="g_list">
          {/*<li>
        Registration fees will be announced on or before 15th September 2024. Please keep following this page. 
        
          </li>*/}
          {/*<li>
        Registration fees mentioned here are inclusive of GST.
          </li>*/}
          <li>
            Registration fee of Rs. 2500 for the workshop covers the following:
            <ul>
              <br />
              <li>Registration kit</li>
              <li>
                Participation in one of the three parallel tracks 
                <br /> 
                <span className="textbf">(The applicant can choose only one of the three tracks mentioned below):</span>
                <br />
                <br />
      
                <span className="infolist">
                  <span className="textbf">Track 1:</span> Physics & Chemistry Of Foods (Dr. Takashi Kuroiwa,
                  Professor, Tokyo City University & Dr. Abhijit Deshpande, IIT Madras)
                </span>
                <br />
                <br />
                <span className="infolist">
                  <span className="textbf">Track 2:</span> Flows At Microscales: From Lab To Nature And Back
                  (Dr. Anupam Sengupta, Professor, University of Luxembourg)
                </span>
                <br />
                <br />
                <span className="infolist">
                  <span className="textbf">Track 3:</span> From Kinetic Theory To Complex Fluids (Dr. Federico
                  Toschi, Professor, Eindhoven University)
                </span>
                <br />
              </li>
              <br />
              <li>Participation in paper writing workshop by Springer Nature</li>
              <li>Lunch on 14th December, and a working lunch on 15th December</li>
              <li>GST</li>
            </ul>
          </li>

          <li>
            Registration cost{" "}
            <span className="inf_red">
              does not include accommodation on any of the days.
            </span>
          </li>
          <li>
            Accommodation arrangements have been made for all participants at
            IIT Hyderabad campus against a charge. Further details may be found
            on{" "}
            <Link to="/accomodation" className="reg_link">
              accomodation page
            </Link>
            .
          </li>
          <li>
          Limited seats are available for the workshop. Registration for individual tracks is going to close once the respective seats are filled up.
          </li>
        </ol>
      </div>
      <div className="table_info">
        {/*<div className="info_acc"><span className="inf_bold">Note:</span> Registration fees does not include accommodation. Accommodation will be provided on campus based on availability.</div>*/}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe38SGktNbysorfCsvP9giOgM--TeKXZWrX-NZdFXklBzJdyA/viewform"
          className="abstact_fw"
          target="_blank"
        >
          <div className="btn-home-enabled abstract_button">
            Click here to register
          </div>
        </a>
      </div>
    </div>
  );
}

export default Reg_Info;
