import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Navbar from "./Navbar";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Venue from "./Venue";
import Sponsors from "./Sponsors";
import Organisers from "./Organisers";
import Technical from "./Technical";
import Abstract from "./Abstract";
import Art from "./Art";
import Registation from "./Registation";
import Accomodation from "./Accomodation";
import Workshops from "./Workshops";
import Program from "./Program";
import Reg_Info from "./Reg_Info";
import Detailed from "./Detailed";
import BAbstracts from "./BAbstracts";
import IITHMap from "./IITHMap";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navbar />}>
      <Route index element={<App />} />
      <Route path="/venue" element={<Venue />} />
      <Route path="/organisers" element={<Organisers />} />
      <Route path="/technical" element={<Technical />} />
      <Route path="/sponsors" element={<Sponsors />} />
      <Route path="/abstract" element={<Abstract />} />
      <Route path="/art" element={<Art />} />
      <Route path="/info" element={<Registation />} />
      <Route path="/sponsors" element={<Sponsors />} />
      <Route path="/accomodation" element={<Accomodation />} />
      <Route path="/workshops" element={<Workshops />} />
      <Route path="/program" element={<Program />} />
      <Route path="/reginfo" element={<Reg_Info />} />
      <Route path="/detailed" element={<Detailed />} />
      <Route path="/book" element={<BAbstracts />} />
      <Route path="/iithmap" element={<IITHMap />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
