import React from 'react'
import './Detailed.css'

function Detailed() {
  return (
    <div className="detailed">
        <div className='heading1'>Detailed Program</div>
        <div className='detail'>Coming Soon...</div>
    </div>
  )
}

export default Detailed