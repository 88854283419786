import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import "./Navbar.css";
import Footer from "./Footer";

const logo = require("../src/assets/logoiith.png");
const icon = require("../src/assets/icon.png");
const rheo = require("../src/assets/rheo.png");
const complogo = require("../src/assets/comp-logo.png");

function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isHoveringHome, setIsHoveringHome] = useState(false);
  const [isHoveringOrg, setIsHoveringOrg] = useState(false);
  const [isHoveringVenue, setIsHoveringVenue] = useState(false);
  const [isHoveringAttendees, setIsHoveringAttendees] = useState(false);
  const [isHoveringProgram, setIsHoveringProgram] = useState(false);
  const [isHoveringWorkshops, setIsHoveringWorkshops] = useState(false);

  // Handlers for mouse enter and leave

  const closeMenu = () => {
    setIsNavExpanded(false);
  };

  const handleOutsideClick = (event) => {
    if (isNavExpanded && !event.target.closest(".navbar")) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isNavExpanded]);

  return (
    <div>
      <div className="navwAnn">
        <div
          style={{
            backgroundColor: "#335ab5",
            padding: "10px 0",
            width: "100%",
          }}
        >
          <Marquee gradient={false} pauseOnHover={true} speed={60}>
            <span
              style={{
                color: "white",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              {/* <span className="marq">
                Decisions on contributed abstracts have been made by chairs and
                have been communicated to all participants.
                <span className="spacing"></span>Please note that the decisions
                on both the type of presentation and the session are final.
                <span className="spacing"></span>If you've submitted an abstract
                but haven't heard back yet, please write to us immediately at{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="mailto:compflu2024@iith.ac.in"
                >
                  compflu2024@iith.ac.in
                </a>
                . <span className="spacing"></span>
              </span> */}

              <span className="marq">
                <b>HARD DEADLINE FOR EARLY BIRD REGISTRATION IS 20 OCTOBER 2024</b>
              </span>
            </span>
          </Marquee>
          <div className="vspacing"></div>
          <Marquee gradient={false} pauseOnHover={true} speed={80}>
            <span
              style={{
                color: "white",
                fontSize: "0.8rem",
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              <span>
                <a
                  href="https://compflu2024.iith.ac.in/info"
                  target="#blank"
                  style={{ textDecoration: "underline" }}
                >
                  Compflu-2024 Registration Open Now.
                </a>
              </span>
              <span className="spacing"></span>
               <span>
                <a
                  href="https://compflu2024.iith.ac.in/reginfo"
                  target="#blank"
                  style={{ textDecoration: "underline" }}
                >
                  Pre-Conference Registration Open Now.
                </a>
              </span>
              <span className="spacing"></span>
              <span>
                {" "}
                Art of Science Deadline:{" "}
                <span className="strikeabs">31 July</span> 1 December 2024.
                <a
                  href="/art"
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontWeight: "700",
                  }}
                >
                  Click Here
                </a>{" "}
                to Submit.
              </span>
              <span className="spacing"> </span> Poster prizes sponsored by
              Springer Nature & Art of Science prizes sponsored by Royal Society
              Chemistry.
              <span className="spacing"> </span>
            </span>
          </Marquee>
        </div>
        <nav className="navbar">
          <div className="comp-nav-full">
            <Link to={"/"}>
              <div className="comp-nav">
                <img src={complogo} className="complogo" alt="compflu-logo" />
                CompFlu-2024
              </div>
            </Link>
          </div>
          <div className={`navtxt ${isNavExpanded ? "expanded" : ""}`}>
            <div
              className="itm"
              onMouseEnter={() => setIsHoveringHome(true)}
              onMouseLeave={() => setIsHoveringHome(false)}
            >
              <Link to={"/"}>Home</Link> <FaCaretDown size={20} />
              {isHoveringHome && (
                <div className="popup-menu">
                  <Link to="/#about" className="popup-item" onClick={closeMenu}>
                    About
                  </Link>
                  <Link to="/#dates" className="popup-item" onClick={closeMenu}>
                    Dates
                  </Link>
                  <Link
                    to="/#contact"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Contact
                  </Link>
                  <Link
                    to="/sponsors"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Sponsors
                  </Link>
                </div>
              )}
            </div>
            <div
              className="itm"
              onMouseEnter={() => setIsHoveringOrg(true)}
              onMouseLeave={() => setIsHoveringOrg(false)}
            >
              <Link to={"/organisers"}>Organisers</Link>{" "}
              <FaCaretDown size={20} />
              {isHoveringOrg && (
                <div className="popup-menu">
                  <Link
                    to="/organisers"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Meeting Organisers
                  </Link>
                  <Link
                    to="/technical"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Technical Program and Chairs
                  </Link>
                </div>
              )}
            </div>
            <div
              className="itm"
              onMouseEnter={() => setIsHoveringProgram(true)}
              onMouseLeave={() => setIsHoveringProgram(false)}
            >
              <Link to={"/program"}>Program</Link> <FaCaretDown size={20} />
              {isHoveringProgram && (
                <div className="popup-menu">
                  <Link
                    to="/program"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Schedule at a glance
                  </Link>
                  <Link
                    to="/detailed"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Detailed Program
                  </Link>
                  <Link
                    to="/book"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Book of Abstracts
                  </Link>
                </div>
              )}
            </div>
            <div
              className="itm"
              onMouseEnter={() => setIsHoveringWorkshops(true)}
              onMouseLeave={() => setIsHoveringWorkshops(false)}
            >
              <Link to="/workshops">Workshop</Link> <FaCaretDown size={20} />
              {isHoveringWorkshops && (
                <div className="popup-menu" style={{ zIndex: 1000 }}>
                  <Link
                    to="/workshops"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Workshop
                  </Link>
                  <Link
                    to="/reginfo"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Workshop Registration
                  </Link>
                </div>
              )}
            </div>
            <div
              className="itm"
              onMouseEnter={() => setIsHoveringAttendees(true)}
              onMouseLeave={() => setIsHoveringAttendees(false)}
            >
              Attendees <FaCaretDown size={20} />
              {isHoveringAttendees && (
                <div className="popup-menu" style={{ zIndex: 1000 }}>
                  <Link to="/info" className="popup-item" onClick={closeMenu}>
                    Registration
                  </Link>
                  <Link to="/art" className="popup-item" onClick={closeMenu}>
                    The Art of Science
                  </Link>
                  <Link
                    to="/accomodation"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Accomodation
                  </Link>
                  <Link
                    to="/abstract"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Submit/Update Abstracts
                  </Link>
                </div>
              )}
            </div>
            <div
              className="itm"
              onMouseEnter={() => setIsHoveringVenue(true)}
              onMouseLeave={() => setIsHoveringVenue(false)}
            >
              <Link to="/venue">Venue</Link> <FaCaretDown size={20} />
              {isHoveringVenue && (
                <div className="popup-menu" style={{ zIndex: 1000 }}>
                  <Link to="/venue" className="popup-item" onClick={closeMenu}>
                    Venue
                  </Link>
                  <Link
                    to="/venue#creche"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    Crèche
                  </Link>
                  <Link
                    to="/iithmap"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    IITH Map
                  </Link>
                  <Link
                    to="/venue#reach"
                    className="popup-item"
                    onClick={closeMenu}
                  >
                    How to Reach
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="icon-and-title">
            <a
              href="https://iith.ac.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="iith-logo" className="iithlogo" />
            </a>
            <a
              href="https://isr.org.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={rheo} alt="rheo-logo" className="rheologo" />
            </a>
          </div>
          <button
            className="hamburger"
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            <img src={icon} className="icon" alt="hamburger-menu" />
          </button>
        </nav>
      </div>
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Navbar;
