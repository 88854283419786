import React from "react";
import {
  FaBus,
  FaPlane,
  FaTrain,
  FaSubway,
  FaBed,
  FaHotel,
} from "react-icons/fa";
import "./Venue.css";
import hydcollage from "./assets/hydcollage.jpg";
import hydcollage2 from "./assets/hydcollage2.jpg";
import iithcollage from "./assets/iithcollage.jpg";
import iithcollage2 from "./assets/iithcollage2.jpg";
import Creche from "./Creche";
import useHashScroll from "./HashScroll";

function Venue() {
  useHashScroll();
  return (
    <div>
      <div style={{ marginBottom: "40px", paddingTop: "100px" }}>
        <div className="hyderabad">
          <div className="title1" style={{ marginBottom: "50px" }}>
            <div
              className="heading1"
              style={{ marginBottom: "5px", textAlign: "left" }}
            >
              Hyderabad
            </div>
            {/*<div style={{"fontSize":"1.8rem", "color":"#AF601A", "fontWeight":"500"}}>The City of Pearls</div>*/}
          </div>
          <div className="about-hyd">
            <div className="hyd-pics">
              <img src={hydcollage} alt="photo" width="100%" height="auto" />
            </div>
            <div className="hyd-txt" style={{ fontSize: "1.15rem" }}>
              <div
                style={{
                  color: "#000099",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                }}
              >
                Known for its rich history, diverse culture, delectable cuise
                with a dash of modern charm, Hyderabad offers a unique blend of
                tradition and technology.
              </div>
              <br />
              As you attend the conference, be sure to explore the city's
              cultural gems. The iconic Charminar stands proudly in the heart of
              the old city, reflecting the grandeur of the Qutb Shahi dynasty.
              Don't miss the enchanting Golconda Fort, a testament to the city's
              medieval past. For a taste of the local cuisine, venture into the
              bustling lanes of the Charminar area, where you'll find delicious
              biryanis and kebabs.
              <br />
              <br />
              Hyderabad's tech hub, HITEC City, showcases the city's
              contemporary side with its skyscrapers and modern infrastructure.
              Immerse yourself in the tranquil beauty of the Hussain Sagar Lake
              or visit the Salar Jung Museum to marvel at its impressive
              collection. Whether you're a history buff, a foodie, or someone
              who enjoys the buzz of a dynamic city, Hyderabad has something
              special to offer.
            </div>
          </div>
        </div>
        <div className="hyderabad">
          <div className="title1" style={{ marginBottom: "50px" }}>
            <div
              className="heading1"
              style={{ marginBottom: "5px", textAlign: "left" }}
            >
              IIT Hyderabad
            </div>
            {/*<div style={{"fontSize":"1.8rem", "color":"#AF601A", "fontWeight":"500"}}>Inventing and Innovating Technology for Humanity</div>*/}
          </div>
          <div className="about-hyd">
            <div className="hyd-txt" style={{ fontSize: "1.15rem" }}>
              <div
                style={{
                  color: "#000099",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                }}
              >
                Established in 2008, the Indian Institute of Technology
                Hyderabad (IIT Hyderabad) stands as a beacon of academic
                excellence in engineering.
              </div>
              <br />
              Renowned for its cutting-edge research and innovation, the
              institution fosters interdisciplinary collaboration and societal
              impact. With a commitment to producing well-rounded graduates, IIT
              Hyderabad provides a vibrant academic environment that encourages
              exploration and leadership.
              <br />
              <br />
              The institute's state-of-the-art infrastructure, robust research
              initiatives, and emphasis on social responsibility contribute to
              its status as a leading technical institution in India, making
              significant strides in technological advancements and leaving a
              lasting impact on the global engineering landscape.
            </div>
            <div className="hyd-pics">
              <img
                height="auto"
                width="100%"
                src={iithcollage2}
                alt="collage"
              />
            </div>
          </div>
        </div>
        <section id="creche">
          <Creche />
        </section>
        <section id="reach">
          <div
            style={{ margin: "auto", textAlign: "center", marginTop: "100px" }}
          >
            <div className="howToReach">How To Reach</div>
            <iframe
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.1691123335986!2d78.11816918809255!3d17.594702640341524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbefdc136bffbb%3A0x73414ff6594c9191!2sIndian%20Institute%20of%20Technology%20Hyderabad!5e0!3m2!1sen!2sin!4v1706207751631!5m2!1sen!2sin"
              style={{ border: 0, marginTop: "40px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="Venue">
            <div className="Card">
              <div className="card">
                <div className="card-body">
                  <div>
                    <FaBus style={{ color: "#000099" }} />
                    <br />
                    <span>
                      <a
                        className="link heading"
                        href="https://maps.app.goo.gl/ecdyxXUiuwUYiV1r8"
                        target="_blank"
                      >
                        From Mahatma Gandhi Bus Station
                      </a>
                    </span>
                    <br />
                    <small className="text-muted">54 Kms</small>
                    <br />
                    <br />
                  </div>
                  <blockquote>
                    <p>
                      Head north towards Mumbai Hwy/NH 65, Via Kukatpally,
                      Patancheruvu
                      <br />
                      <br />
                      Merge onto ORR and Take exit towards Mumbai/NH65
                      <br />
                      <br />
                      Drive to Mumbai Hwy/NH 65 for 15 km, you will find the
                      sign board of IITH on Right side
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="Card">
              <div className="card">
                <div className="card-body">
                  <div>
                    <FaPlane style={{ color: "#000099" }} />
                    <br />
                    <span>
                      <a
                        className="link heading"
                        href="https://www.google.com/maps/dir/Rajiv+Gandhi+International+Airport+(HYD),+Shamshabad,+Hyderabad,+Telangana/IIT+Hyderabad,+Sangareddy,+Telangana/@17.4116862,78.1035726,11z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x3bcbbb7fb1d91b45:0x532029ec33eaa851!2m2!1d78.4293851!2d17.2402633!1m5!1m1!1s0x3bcbf18b7cc60013:0x2e4802867d207da!2m2!1d78.1197295!2d17.5847123?coh=198942&entry=tts"
                        target="_blank"
                      >
                        From the Airport (RGIA)
                      </a>
                    </span>
                    <br />
                    <small className="text-muted">64 Kms</small>
                    <br />
                    <br />
                  </div>
                  <blockquote>
                    <p>
                      Get on ORR in Gandiguda from Airport Approach Road
                      <br />
                      <br />
                      Follow ORR to Muthangi, Hyderabad. Take exit 3 from ORR
                      <br />
                      <br />
                      Drive to Mumbai Hwy/NH 9 for 15 km, you will find the sign
                      board of IITH on Right side
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="Card">
              <div className="card">
                <div className="card-body">
                  <div className="upper-body">
                    <FaTrain style={{ color: "#000099" }} />
                    <br />
                    <span>
                      <a
                        className="link heading"
                        href="https://www.google.com/maps/dir/Secunderabad+Junction,+Secunderabad+Railway+Station+Rd,+Railway+Officer+Colony,+Botiguda,+Bhoiguda,+Secunderabad,+Telangana+500025/IIT+Hyderabad,+Sangareddy,+Telangana/@17.5095336,77.9752437,10z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x3bcb9a213de9c7b7:0x957c784f25ea95aa!2m2!1d78.5015742!2d17.4337454!1m5!1m1!1s0x3bcbf18b7cc60013:0x2e4802867d207da!2m2!1d78.1197295!2d17.5847123?coh=198942&entry=tts"
                        target="_blank"
                      >
                        From Secundarabad Railway Station
                      </a>
                    </span>
                    <br />
                    <small className="text-muted">50 Kms</small>
                    <br />
                    <br />
                  </div>
                  <blockquote>
                    <p>
                      Take SD Road to Old Airport Rd in New Bowenpally via
                      Mahatma Gandhi Rd/NH 7
                      <br />
                      <br />
                      Reach Bhavani Nagar and drive to Mumbai Hwy/NH 65
                      <br />
                      <br />
                      Continue to drive on Mumbai Hwy/NH 65, you will find the
                      sign board of IITH on Right side
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="Card">
              <div className="card">
                <div className="card-body">
                  <div className="upper-body">
                    <FaSubway style={{ color: "#000099" }} />
                    <br />
                    <span>
                      <a
                        className="link heading"
                        href="https://www.google.com/maps/dir/Secunderabad+East+Metro+station/Miyapur+Metro+Station,+Nadigada+Tanda,+Hyderabad,+Telangana/@17.4669446,78.3971419,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x3bcb9b365a77ee01:0x391944325a54a219!2m2!1d78.5055339!2d17.4355331!1m5!1m1!1s0x3bcb9332923f5683:0x8a776e3dd160ac05!2m2!1d78.3730723!2d17.4965338!3e3?coh=198942&entry=tts"
                        target="_blank"
                      >
                        Metro Route
                      </a>
                    </span>
                    <br />
                    <small className="text-muted">52 Kms</small>
                    <br />
                    <br />
                  </div>
                  <blockquote>
                    <p>
                      From Secunderabad Railway Station, walk 600 meters to
                      reach Secunderabad-East Metro Station.
                      <br />
                      <br />
                      Purchase a ticket to Miyapur Station and change metros
                      accordingly.
                      <br />
                      <br />
                      Get off at Ameerpet Station, board a metro headed towards
                      Miyapur, and then take a bus from Miyapur Bus Stop to
                      Patancheru Bus Station. Follow signs to IIT Hyderabad in
                      Sangareddy Town on the right side.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
    </div>
  );
}

export default Venue;
